import { useState, useMemo } from "react";
import { GetStaticProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { cx } from "class-variance-authority";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LanguagePopup } from "components/LanguageSelector";
import { DesktopFooter } from "components/DesktopFooter";
import {
    HomepageUpcomingCard,
    VenueOptionsProps,
    VenueSheet,
} from "components/HomePage/v2";
import { Section } from "components/Section";
import { ScrollArea } from "components/ScrollArea";
import { TFunction } from "i18next";

import { PendingPayments } from "components/HomePage";
import BottomTabBar from "components/BottomTabBar";
import { renderCentreImage } from "components/CentreCard";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselDots,
} from "components/Carousel";
import Autoplay from "embla-carousel-autoplay";
import { ArrowRightUnfilled } from "components/Icons";
import { FilterOptions, FilterRadioGroup } from "components/Filter";
import {
    SearchBarForm,
    FormValues,
    FormQueryParams,
} from "components/SearchPage";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { GameCard } from "components/Cards/GameCard";
import { DesktopHeader } from "components/DesktopHeader";
import {
    FeatureVenues,
    FeatureVenuesSlice,
    featureVenueCategoriesFragment,
} from "components/FeatureVenues";

import dayjs from "lib/dayjs";
import { Image } from "lib/imgproxy";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { LogomarkWithNameWhite } from "assets/vectors/logo";
import { FragmentType, getFragment, graphql } from "lib/gql";
import { useQuery } from "@apollo/client";
import { unique } from "utils/index";
import { useAuth } from "lib/firebase/hooks";
import { BlankLayoutV2 } from "layouts/BlankLayout";
import { CategoryListContext } from "components/CategoryList";
import { useBreakpoint } from "utils/breakpoints";
import {
    HomepagePublicGameQuery,
    PelangganHomePageQuery,
} from "lib/gql/graphql";
import {
    getRepositoryEndpoint,
    createClient,
    PrismicDocument,
} from "@prismicio/client";

const query = graphql(`
    query pelangganHomePage(
        $now: DateTime!
        $startsAfter: DateTime!
        $isLoggedIn: Boolean!
    ) {
        upcomingBookings: myGroupedBookings(
            filter: {
                byStatus: { confirmed: true, unconfirmed: true }
                byStartDateAfter: $now
            }
            limit: 3
            order: ASC
        ) @include(if: $isLoggedIn) {
            edges {
                node {
                    referenceId
                    startDt
                    endDt
                    bookings {
                        tenant {
                            uid
                            name
                        }
                        uid
                        created
                        tenantId
                        startDt
                        endDt
                        confirmed
                        cancelled
                        service {
                            uid
                            serviceMode
                            categoryName
                        }
                        resources {
                            resourceId
                            resourceName
                        }
                        metadata
                    }
                }
            }
        }
        publicGameCount(startsAfter: $startsAfter) {
            categoryId
            gameCount
            locationTenantIds
        }
        categories {
            ...FeatureVenueCategories
            ...SocialGamesCategories
        }
        tenantIdsFromPastBookings(limit: 3) @include(if: $isLoggedIn)
        courtsitePartners(sortOrder: BY_WEIGHTAGE_DESC) {
            uid
            name
            metadata
        }
    }
`);

const publicGamesQuery = graphql(`
    query homepagePublicGame($filter: PublicGamesFilter, $page: PageInput) {
        publicGames(filter: $filter, page: $page) {
            edges {
                node {
                    uid
                    name
                    categoryId
                    startDt
                    endDt
                    maxPlayers
                    location {
                        tenant {
                            uid
                            name
                        }
                    }
                    organizer {
                        name
                    }
                    players {
                        displayPicture
                    }
                    metadata
                    reservedPlayers
                }
            }
        }
    }
`);

const socialGamesCategoriesFragment = graphql(`
    fragment SocialGamesCategories on Category {
        uid
        name
    }
`);

type PageDocProps = {
    homePageDoc: PrismicDocument;
    featureVenueDoc: PrismicDocument;
    categoryList: PrismicDocument;
};
type HomePageProps = { pageDoc: PageDocProps };
type PrismicLink = {
    link_type?: "Web" | "Document" | "Media" | "Any";
    url?: string;
    target?: string;
    id?: string;
    uid?: string;
    isBroken?: boolean;
    lang?: string;
    slug?: string;
    tags?: string[];
    type?: string;
    height?: string;
    kind?: string;
    name?: string;
    size?: string;
    width?: string;
};
type DealsSlice = {
    items: {
        title: RichTextBlock[];
        description: RichTextBlock[];
        img: RichTextBlock;
        link: PrismicLink;
    }[];
};
type HomePagePrismicData = [DealsSlice];
type TFunc = TFunction<["pages/index", "common"]>;
type SocialGamesProp = {
    filterGame?: string;
    setFilterGame: (v?: string) => void;
    publicGameCount: PelangganHomePageQuery["publicGameCount"];
    categories: FragmentType<typeof socialGamesCategoriesFragment>[];
    publicGames?: HomepagePublicGameQuery;
    t: TFunc;
};
type Centre = {
    name: string;
    uid: string;
    cityAndState: string;
    cover?: string;
    imagesWithTags?: { url: string; tags: string[] }[];
};
type HomePageSectionProps = {
    title: string;
    description?: string;
    className?: string;
    children?: React.ReactNode;
    show: boolean;
};
type DealsCarouselProp = {
    title: string;
    description: string;
    imageUrl: string;
    link: string;
};

const HomePage = ({ pageDoc }: HomePageProps): JSX.Element => {
    const { user } = useAuth();
    const router = useRouter();
    const { lg: isDesktop } = useBreakpoint();
    const { homePageDoc, featureVenueDoc, categoryList } = pageDoc;
    const { t } = useTranslation(["pages/index", "common"]);
    const form = useForm<FormValues>({ mode: "onChange" });
    const [currentTime] = useState(new Date());
    const [filterGame, setFilterGame] = useState<string | undefined>("all");
    const { data, loading } = useQuery(query, {
        fetchPolicy: "cache-and-network",
        variables: {
            now: currentTime.toISOString(),
            startsAfter: currentTime.toISOString(),
            isLoggedIn: !!user,
        },
    });
    const { data: publicGames } = useQuery(publicGamesQuery, {
        variables: {
            filter: {
                categoryId: filterGame == "all" ? undefined : filterGame,
            },
            page: { limit: 3 },
        },
        fetchPolicy: "cache-and-network",
    });

    const prisimcData = homePageDoc.data.body as HomePagePrismicData;
    const featureVenue = featureVenueDoc.data as FeatureVenuesSlice;

    const upcomingBookingsData = data?.upcomingBookings;
    const categories = data?.categories ?? [];
    const socialGames = {
        filterGame,
        setFilterGame: (v?: string) => setFilterGame(v),
        publicGameCount: data?.publicGameCount ?? [],
        categories,
        publicGames,
        t: t,
    };

    const pastBookedCentres = useMemo(() => {
        if (!data?.tenantIdsFromPastBookings) return [];
        const pastBookingsTenantIds = data.tenantIdsFromPastBookings ?? [];
        return data.courtsitePartners
            .filter((c) => pastBookingsTenantIds.includes(c.uid))
            .map((centre) => {
                const metadata =
                    (centre.metadata && JSON.parse(centre.metadata)) || {};
                const cityAndState = metadata.cityAndState ?? "";
                const images = metadata.centreImageURLs ?? [];
                const imagesWithTags = metadata.centreImages ?? [];
                const cover = images[0] ?? "";
                return {
                    ...centre,
                    cityAndState,
                    cover,
                    imagesWithTags,
                };
            });
    }, [data?.tenantIdsFromPastBookings, data?.courtsitePartners]);

    const venueOptions: VenueOptionsProps[] | undefined = useMemo(
        () =>
            data?.courtsitePartners.map((v) => ({
                id: v.uid,
                text: v.name,
                link: `/centre/${v.name}/${v.uid}`,
            })),
        [data?.courtsitePartners],
    );

    const onSubmit: SubmitHandler<FormValues> = async (v) => {
        if (!v.categoryId && !v.locationId) {
            router.push({ pathname: "/search" });
            return;
        }
        const query: FormQueryParams = {};
        if (v.locationId) query.locationId = v.locationId;
        if (v.categoryId) query.categoryId = v.categoryId;
        if (v.dtrange?.date) query.date = v.dtrange.date;
        if (v.dtrange?.time) query.time = v.dtrange.time;
        if (v.dtrange?.meridiem) query.meridiem = v.dtrange.meridiem;
        if (v.dtrange?.duration) query.duration = v.dtrange.duration;
        router.push({ pathname: "/search/result", query });
    };

    return (
        <div className="lg:pb-20">
            <DesktopHeader />
            <Head>
                <title>Courtsite</title>
            </Head>
            <PendingPayments isDesktop={isDesktop} />
            <div className="flex w-full flex-col items-center bg-white pb-[100px] md:p-0 lg:bg-inherit">
                <div className="relative mb-10 hidden w-full justify-center lg:flex">
                    <div
                        className="h-[500px] w-full bg-cover bg-center"
                        style={{
                            backgroundImage:
                                "linear-gradient(to bottom, #00000080, #00000080), url(/images/cover_desktop.png)",
                        }}
                    />
                    <div className="absolute top-[235px] mx-auto flex w-full flex-col items-center gap-8">
                        <div className="flex flex-col items-center gap-3">
                            <div className="typography-display-sm font-bold text-white">
                                {t(
                                    "headerTitle",
                                    "Get Active, Book Your Games Now",
                                )}
                            </div>
                            <div className="typography-h3 text-center text-white">
                                {t(
                                    "headerDescription",
                                    "From favorites like badminton and futsal to trendy pickleball and frisbee, play all kinds of sports nationwide!",
                                )}
                            </div>
                        </div>
                        <div className="w-full lg:max-w-[1056px]">
                            <FormProvider {...form}>
                                <CategoryListContext.Provider
                                    value={categoryList}
                                >
                                    <SearchBarForm onSubmit={onSubmit} />
                                </CategoryListContext.Provider>
                            </FormProvider>
                        </div>
                    </div>
                </div>
                <div className="w-screen lg:max-w-[1056px]">
                    <div className="relative lg:hidden">
                        <div className="relative flex w-full">
                            <div
                                className="inset-0 block aspect-video w-full bg-cover bg-center"
                                style={{
                                    backgroundImage:
                                        "linear-gradient(0deg, rgba(0, 0, 0, 0) 4.62%, rgba(0, 0, 0, 0.5) 88.5%), url(/images/cover_mobile.png)",
                                }}
                            />
                        </div>
                        <div className="absolute inset-0 z-10">
                            <div className="flex h-[75px] w-full justify-between bg-transparent p-4 pt-7 lg:hidden">
                                <LogomarkWithNameWhite />
                                <LanguagePopup />
                            </div>
                        </div>
                    </div>
                    <div className="relative z-10 mt-[-30px] px-4 lg:hidden">
                        <VenueSheet venueOptions={venueOptions} />
                    </div>
                    <HomeFeatureSection t={t} />
                    <Section className="flex flex-col pt-0 lg:gap-7 lg:py-8">
                        <UpcomingBookingsSection
                            upcomingBookingsData={upcomingBookingsData}
                            loading={loading}
                            t={t}
                        />
                        <BookAgainSection
                            pastBookedCentres={pastBookedCentres}
                            loading={loading}
                            t={t}
                        />
                        <DealsCarousel pageDoc={prisimcData} t={t} />
                        <FeatureVenueCarousel
                            featureVenue={featureVenue}
                            categories={categories}
                            loading={loading}
                            t={t}
                        />
                        <div className="hidden lg:contents">
                            <SocialGames {...socialGames} />
                        </div>
                    </Section>
                </div>
            </div>
            <BottomTabBar activeTab="home" />
            <DesktopFooter />
        </div>
    );
};
HomePage.Layout = BlankLayoutV2;
export default HomePage;

const HomeFeatureSection = ({ t }: { t: TFunc }): JSX.Element => {
    const homeFeatureButtons = [
        {
            alt: "Play",
            src: "/images/homeIcons/play.png",
            text: t("common:play", "Play"),
            href: "/explore/category",
        },
        {
            alt: "Book",
            src: "/images/homeIcons/book.png",
            text: t("common:book", "Book"),
            href: "/search",
        },
        {
            alt: "Join",
            src: "/images/homeIcons/join.png",
            text: t("common:join", "Join"),
            href: "/explore/games",
        },
        {
            alt: "Deals",
            src: "/images/homeIcons/deals.png",
            text: t("common:deals", "Deals"),
            href: "/deals",
        },
    ];

    return (
        <div className="flex w-full justify-center lg:hidden">
            <div className="grid grid-cols-4 gap-[21px] px-4 pb-4 pt-3">
                {homeFeatureButtons.map((b) => (
                    <div key={b.alt}>
                        <Link href={b.href}>
                            <a className="remove-styles-a flex flex-col items-center gap-2">
                                <div className="flex justify-center rounded-lg bg-white p-4 shadow-sm">
                                    <img
                                        alt={b.alt}
                                        src={b.src}
                                        className="size-10"
                                    />
                                </div>
                                <span className="typography-sub mx-auto mt-auto capitalize text-blue-grey-800 hover:text-blue-grey-800">
                                    {b.text}
                                </span>
                            </a>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

const UpcomingBookingsSection = ({
    upcomingBookingsData,
    loading,
    t,
}: {
    upcomingBookingsData?: PelangganHomePageQuery["upcomingBookings"];
    loading: boolean;
    t: TFunc;
}): JSX.Element => {
    const upcomingBookings =
        upcomingBookingsData?.edges.map((e) => {
            const { bookings, startDt, endDt, referenceId } = e.node;
            const firstBooking = bookings[0];
            if (!firstBooking) {
                throw new Error("error with grouped bookings");
            }
            const resources = unique(
                bookings.flatMap((b) =>
                    b.resources.map((r) => ({
                        uid: r.resourceId,
                        name: r.resourceName,
                    })),
                ),
            );
            const bookingIds = bookings.map((b) => b.uid);
            const bookingMetadata = JSON.parse(firstBooking.metadata);
            return {
                key: `${referenceId}-${startDt}-${endDt}`,
                startDt,
                endDt,
                categoryName: firstBooking.service.categoryName,
                centreName: firstBooking.tenant.name,
                resources: resources,
                confirmed: firstBooking.confirmed,
                isAllCancelled: bookings.every((b) => !!b.cancelled),
                rescheduledBooking:
                    bookingMetadata.workflowType ===
                    "CourtsiteRescheduleWorkflow",
                workflowId: bookingMetadata.workflowId as string | undefined,
                href: {
                    pathname: "/user/bookings",
                    query: {
                        bookingIds: bookingIds,
                    },
                },
            };
        }) ?? [];
    return (
        <HomePageSection
            title={t("upcomingBookingTitle", "Your upcoming bookings")}
            show={upcomingBookings.length > 0 && !loading}
        >
            <ScrollArea orientation="horizontal">
                <div className="grid min-w-0 auto-rows-[182px] grid-cols-[repeat(3,minmax(250px,1fr))] gap-4">
                    {upcomingBookings.map((b) => (
                        <HomepageUpcomingCard
                            {...b}
                            key={b.key}
                            href={b.href}
                            loading={loading}
                        />
                    ))}
                </div>
            </ScrollArea>
        </HomePageSection>
    );
};

const BookAgainSection = ({
    pastBookedCentres,
    loading,
    t,
}: {
    pastBookedCentres: Centre[];
    loading: boolean;
    t: TFunc;
}): JSX.Element => (
    <HomePageSection
        title={t("bookAgainTitle", "Why Not Book Again?")}
        show={pastBookedCentres.length > 0 && !loading}
    >
        <ScrollArea orientation="horizontal">
            <div className="grid min-w-0 flex-1 auto-rows-[228px] grid-cols-[repeat(5,minmax(214px,1fr))] gap-4">
                {pastBookedCentres.map((c) => (
                    <li
                        key={`bookAgain_${c.uid}`}
                        className="flex cursor-pointer flex-col gap-2 rounded-xl border-[0.5px] border-solid border-blue-grey-50 bg-white pb-4 transition-all lg:group-data-[filter=hide]:w-[332px] lg:group-data-[filter=show]:w-[320px]"
                    >
                        <div className="relative aspect-video w-full">
                            <Image
                                alt="Default centre image"
                                src={renderCentreImage(c)}
                                layout="fill"
                                objectFit="cover"
                                className="rounded-t-xl"
                            />
                        </div>
                        <section className="mx-2 flex flex-1 flex-col justify-between gap-5">
                            <div>
                                <span className="typography-h4 line-clamp-1 font-bold text-blue-grey-900">
                                    {c.name}
                                </span>
                                <span className="typography-tiny line-clamp-1 text-blue-grey-500">
                                    {c.cityAndState}
                                </span>
                            </div>
                            <nav className="mt-auto">
                                <Link
                                    href={{
                                        pathname:
                                            "/centre/[orgName]/[orgID]/select",
                                        query: {
                                            orgID: c.uid,
                                            orgName: c.name,
                                            noInit: true,
                                        },
                                    }}
                                    passHref
                                >
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="w-full"
                                    >
                                        {t("common:book_now", "Book now")}
                                    </Button>
                                </Link>
                            </nav>
                        </section>
                    </li>
                ))}
            </div>
        </ScrollArea>
    </HomePageSection>
);

const HomePageSection = ({
    title,
    description,
    className,
    children,
    show,
}: HomePageSectionProps): JSX.Element => (
    <div
        data-show={show ? "true" : "false"}
        className={cx(
            "hidden flex-col gap-3 pt-2.5 data-[show=true]:flex lg:gap-6",
            className,
        )}
    >
        <div>
            <div className="typography-h4 lg:typography-h2 font-semibold capitalize text-blue-grey-900 lg:font-semibold">
                {title}
            </div>
            <div
                data-show={description ? "true" : "false"}
                className="typography-main mt-1 hidden text-blue-grey data-[show=false]:hidden lg:contents"
            >
                {description}
            </div>
        </div>
        {children}
    </div>
);

const DealsCarousel = ({
    pageDoc,
    t,
}: {
    pageDoc: HomePagePrismicData;
    t: TFunc;
}): JSX.Element => {
    const options: DealsCarouselProp[] = pageDoc[0].items
        .map((i) => ({
            title: getText(i.title),
            description: getText(i.description),
            link: i.link.url ?? "",
            imageUrl: i.img.url ?? "",
        }))
        .filter((i) => i.imageUrl.trim() && i.link.trim());
    if (options.length < 1) return <></>;

    return (
        <HomePageSection
            title={t("highlightsTitle", "Highlights")}
            className="pb-2.5"
            show
        >
            <div className="flex justify-center">
                <Carousel
                    opts={{
                        align: "start",
                        loop: true,
                        watchDrag: false,
                    }}
                    plugins={[Autoplay({ delay: 3000 })]}
                    className="h-fit w-full flex-col gap-5"
                >
                    <CarouselContent className="ml-0 mr-0">
                        {options.map((i) => {
                            return (
                                <CarouselItem key={i.imageUrl} className="pr-4">
                                    <Link href={i.link}>
                                        <div>
                                            <div
                                                className="flex aspect-video w-[300px] cursor-pointer flex-col justify-between rounded-xl bg-cover bg-center p-4 shadow-md lg:w-[400px] lg:px-4 lg:py-5"
                                                style={{
                                                    backgroundImage: `url(${i.imageUrl})`,
                                                }}
                                            />
                                            <div className="typography-sub lg:typography-main mt-2 font-bold text-blue-grey-600 lg:font-bold">
                                                {i.title}
                                            </div>
                                            <div className="typography-tiny lg:typography-sub text-blue-grey-300">
                                                {i.description}
                                            </div>
                                        </div>
                                    </Link>
                                </CarouselItem>
                            );
                        })}
                    </CarouselContent>
                    <CarouselDots position="center" />
                </Carousel>
            </div>
        </HomePageSection>
    );
};

const FeatureVenueCarousel = ({
    featureVenue,
    categories,
    loading,
    t,
}: {
    featureVenue: FeatureVenuesSlice;
    categories: FragmentType<typeof featureVenueCategoriesFragment>[];
    loading?: boolean;
    t: TFunc;
}): JSX.Element => (
    <HomePageSection
        title={t("exploreMoreTitle", "Explore more")}
        show={!loading}
    >
        <FeatureVenues
            key={`featureVenue_${featureVenue.category.length}`}
            featureVenue={featureVenue}
            categories={categories}
            categoryLoading={loading}
        />
    </HomePageSection>
);

const SocialGames = ({
    filterGame,
    setFilterGame,
    publicGameCount,
    categories,
    publicGames,
    t,
}: SocialGamesProp): JSX.Element => {
    const categoryFragment = getFragment(
        socialGamesCategoriesFragment,
        categories,
    );
    const gameIds = publicGameCount.map((b) => b.categoryId);
    const gameCategories = categoryFragment.filter((c) =>
        gameIds.includes(c.uid),
    );

    const getCategoryCount = (categoryId: string): number => {
        const category = publicGameCount.find(
            (b) => b.categoryId === categoryId,
        );
        return category?.gameCount || 0;
    };
    const optionsCategory: FilterOptions<string>[] = [
        { label: "All", value: "all" },
        ...gameCategories
            .sort((a, b) => getCategoryCount(b.uid) - getCategoryCount(a.uid))
            .map((c) => ({ label: c.name, value: c.uid })),
    ];

    const publicGamesEdge = publicGames?.publicGames.edges || [];
    const publicGameNodes = publicGamesEdge.map((g) => g.node);
    const games = publicGameNodes.map((g) => {
        type PublicGameMetadaata = {
            details: string;
            playersOrTeams: string;
        };
        const metadata: PublicGameMetadaata = JSON.parse(g.metadata);
        const categoryName: string =
            categoryFragment.find((sc) => sc.uid === g.categoryId)?.name ?? "";

        const players = g.players?.map((p) => ({
            displayPicture: p.displayPicture ?? undefined,
        }));
        return {
            uid: g.uid,
            name: g.name,
            categoryId: g.categoryId,
            categoryName,
            location: g.location.tenant,
            startDt: dayjs(g.startDt).tz(),
            endDt: dayjs(g.endDt).tz(),
            organizerName: g.organizer.name,
            players: players || [],
            maxPlayers: g.maxPlayers,
            details: metadata.details,
            playersOrTeams: metadata.playersOrTeams || "player",
            reservedPlayers: g.reservedPlayers || 0,
        };
    });

    return (
        <HomePageSection
            title={t("socialGamesTitle", "Top social games")}
            description={t(
                "socialGamesDescription",
                "Playing sports is the best way to socialize. Go for our social games to make friends or pick up a new hobby!",
            )}
            className="pb-2.5"
            show
        >
            <div className="flex flex-col gap-6">
                <FilterRadioGroup
                    scrollable
                    options={optionsCategory}
                    selected={filterGame}
                    onChange={(v) => setFilterGame(v)}
                    className="max-w-[1056px]"
                />
                <div className="grid min-w-0 auto-rows-[342px] grid-cols-[repeat(3,minmax(303.5px,1fr))_77px] gap-3">
                    {games.map((game) => (
                        <GameCard
                            key={game.uid}
                            game={game}
                            showFilter={false}
                        />
                    ))}
                    <Link href="explore/games">
                        <a className="remove-styles-a flex cursor-pointer flex-col items-end justify-center gap-2">
                            <div className="typography-h5 text-wrap font-semibold text-blue-grey-600">
                                {t("moreGames", "More games")}
                            </div>
                            <div className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                                <ArrowRightUnfilled className="size-3 text-blue-grey-600" />
                            </div>
                        </a>
                    </Link>
                </div>
            </div>
        </HomePageSection>
    );
};

const getText = (richText: RichTextBlock[]): string =>
    RichText.asText(richText);

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
    const endpoint = getRepositoryEndpoint("courtsite");
    const client = createClient(endpoint);

    const doc = await client.getSingle("pelanggan_home_v2", {});
    const featureVenue = await client.getSingle("feature_venue", {});
    const categoryList = await client.getByID("ZoTgmxAAACEA3x4L", {});

    return {
        props: {
            pageDoc: {
                homePageDoc: doc,
                featureVenueDoc: featureVenue,
                categoryList: categoryList,
            },
        },
    };
};
