import { css } from "@emotion/react";
import { List, Button } from "antd";
import dayjs from "lib/dayjs";
import { BookingCard } from "components/BookingCard";
import { useTranslation } from "react-i18next";

const styles = {
    subHeaderContainer: css`
        background: #ebeefd;
        width: 100%;
        border-radius: 8px;
        font-weight: 700;
        text-align: center;
        line-height: 2;
        margin-top: 12px;
    `,
};

type Booking = {
    tenantId: string;
    categoryName?: string;
    referenceId: string;
    start: string;
    end: string;
    confirmed?: string | null;
    courtNames: string;
    bookingIds: string[];
    isAllCancelled: boolean;
    isDailyService: boolean;
    metadata: {
        workflowId?: string;
        invoiceId?: string;
        workflowType?: string;
    };
};
type BookingListProps = {
    loading?: boolean;
    bookings: Booking[];
    bookingsPageInfo: {
        hasNextPage: boolean;
    };
    isBookingsDescendingOrder?: boolean;
    handleLoadMoreData: () => void;
};
const BookingList = ({
    loading,
    bookings,
    bookingsPageInfo,
    handleLoadMoreData,
    isBookingsDescendingOrder,
}: BookingListProps): JSX.Element => {
    const { t, ready } = useTranslation("components/BookingList");
    if (!ready) return <></>;
    const handleLoadMore = bookingsPageInfo.hasNextPage && (
        <div className="flex justify-center">
            <div className="w-full max-w-[768px]">
                <Button
                    type="primary"
                    size="large"
                    block
                    ghost
                    onClick={handleLoadMoreData}
                    loading={loading}
                >
                    {t("load_more", {
                        defaultValue: "Load More Bookings",
                    })}
                </Button>
            </div>
        </div>
    );

    bookings.sort((a, b) => a.start.localeCompare(b.start));
    if (isBookingsDescendingOrder) {
        bookings.sort((a, b) => b.start.localeCompare(a.start));
    }
    const groupedBookingsByDate = bookings.reduce<
        { date: string; bookings: typeof bookings }[]
    >((acc, booking) => {
        const date = dayjs(booking.start).tz().format("dddd, D MMM YYYY");
        const group = acc.find((group) => group.date === date);
        if (!group) {
            acc.push({ date, bookings: [booking] });
        } else {
            group.bookings.push(booking);
        }
        return acc;
    }, []);

    return (
        <div>
            <List
                loading={loading}
                dataSource={groupedBookingsByDate}
                loadMore={handleLoadMore}
                renderItem={(item) => (
                    <div key={item.date}>
                        <div css={styles.subHeaderContainer}>{item.date}</div>
                        {item.bookings.map((itemBookings) => (
                            <BookingCard
                                groupedBookingDetails={itemBookings}
                                key={
                                    itemBookings.referenceId +
                                    itemBookings.start +
                                    itemBookings.end
                                }
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    );
};

type UngroupedBooking = {
    uid: string;
    created: string;
    tenantId: string;
    referenceId: string;
    startDt: string;
    endDt: string;
    confirmed?: string | null;
    cancelled?: string | null;
    service?: {
        uid: string;
        serviceMode: string;
        category?: { uid: string; name: string } | null;
    } | null;
    resources: {
        uid: string;
        archived?: string | null;
        resource: { uid: string; name: string };
    }[];
    metadata: {
        invoiceId?: string;
        workflowId?: string;
        workflowType?: string;
    };
};

BookingList.groupBookings = (
    bookings: UngroupedBooking[],
    bookingIdsWithoutInvoices: string[] = [],
): Booking[] => {
    return bookings
        .flatMap(({ resources, ...b }) =>
            resources
                .filter((r) => !r.archived)
                .map((r) => ({
                    ...b,
                    resourceName: r.resource.name,
                })),
        )
        .sort(
            (a, b) =>
                a.startDt.localeCompare(b.startDt) ||
                a.endDt.localeCompare(b.endDt) ||
                a.resourceName.localeCompare(b.resourceName, undefined, {
                    numeric: true,
                }) ||
                a.referenceId.localeCompare(b.referenceId),
        )
        .reduce<Booking[]>((acc, booking) => {
            // filters bookings that do not have existing invoices tied to them past 10 minutes
            if (
                bookingIdsWithoutInvoices.includes(booking.uid) &&
                dayjs().unix() >=
                    dayjs(booking.created).add(10, "minute").unix()
            ) {
                return acc;
            }
            const isDailyService =
                booking.service?.serviceMode === "DAILY_SERVICE";
            const group = acc.find(
                (g) =>
                    g.start === booking.startDt &&
                    g.end === booking.endDt &&
                    g.referenceId === booking.referenceId &&
                    g.isDailyService === isDailyService,
            );
            if (!group) {
                return [
                    ...acc,
                    {
                        tenantId: booking.tenantId,
                        categoryName: booking.service?.category?.name,
                        referenceId: booking.referenceId,
                        start: booking.startDt,
                        end: booking.endDt,
                        courtNames: booking.resourceName,
                        bookingIds: [booking.uid],
                        isAllCancelled: !!booking.cancelled,
                        confirmed: booking.confirmed,
                        isDailyService,
                        metadata: booking.metadata,
                    },
                ];
            }
            group.bookingIds.push(booking.uid);
            group.courtNames += ", " + booking.resourceName;
            group.isAllCancelled &&= !!booking.cancelled;
            group.confirmed &&= booking.confirmed;
            return acc;
        }, []);
};

export default BookingList;
