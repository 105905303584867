import { css } from "@emotion/react";
import Link from "next/link";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Button, Skeleton, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";

import dayjs from "lib/dayjs";
import { colourCss } from "config/theme";

const styles = {
    card: css({
        width: 250,
        height: "100%",
        cursor: "pointer",
        ".ant-card-head": {
            borderBottom: "none",
            lineHeight: "13px",
            padding: "0 14px",
        },
        ".ant-card-body": {
            padding: "0 14px 16px",
        },
    }),
    categoryTitle: css({
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "13px",
        letterSpacing: "0em",
    }),
    centreTitle: css({
        fontFamily: "Lato",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0em",
    }),
    bookingTextContainer: css({
        fontFamily: "Lato",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: "0em",
        textAlign: "left",
        paddingBottom: "16px",
        height: "50px",
    }),
    centreTitleContainer: css({
        marginTop: "5px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }),
    button: css({
        padding: 0,
        fontSize: "15px",
        ".anticon svg": {
            fontSize: "9px",
            marginLeft: "-5px",
            marginBottom: "1px",
        },
    }),
};

type UpcomingBookingCardData = {
    organisation: { uid: string; name: string };
    categories: { uid: string; name: string }[];
};
const upcomingBookingCardQuery = gql`
    query upcomingBookingCard($tenantId: ID!) {
        organisation(uid: $tenantId) {
            uid
            name
        }
        categories {
            uid
            name
        }
    }
`;

const dateFormat = "DD/M/YYYY";
const timeFormat = "hh:mm A";

type GroupedBookingsDetails = {
    tenantId: string;
    categoryId?: string;
    start: string;
    end: string;
    uid: string[];
    courtUUID: string[];
    serviceMode: "HOURLY_SERVICE" | "DAILY_SERVICE";
};
type BookingCardProps = { groupedBookingsDetails: GroupedBookingsDetails };
const UpcomingBookingCard = ({
    groupedBookingsDetails,
}: BookingCardProps): JSX.Element => {
    const { t, ready } = useTranslation("components/BookingCard");
    const { uid, tenantId, start, end, categoryId, serviceMode } =
        groupedBookingsDetails;

    const isDailyServiceBooking = serviceMode === "DAILY_SERVICE";

    const { data, loading } = useQuery<UpcomingBookingCardData>(
        upcomingBookingCardQuery,
        { variables: { tenantId: tenantId } },
    );

    if (loading || !data || !ready) {
        return (
            <Card css={styles.card}>
                <Skeleton active={true} paragraph={{ rows: 3 }} />
            </Card>
        );
    }

    const categoryName: string =
        data.categories.find((sc) => sc.uid === categoryId)?.name ?? "";
    const upcomingBookingCardTitle = [
        <div key={uid[0]}>
            <span css={[colourCss.secondaryBright, styles.categoryTitle]}>
                {categoryName.toUpperCase()}
            </span>
            <div css={styles.centreTitleContainer}>
                <span css={styles.centreTitle}>{data.organisation.name}</span>
            </div>
        </div>,
    ];
    const startDt = dayjs(start).tz();
    const endDt = dayjs(end).tz();

    let upcomingTxt = (
        <>
            <div>{startDt.format(dateFormat)}</div>
            <div>
                {startDt.format(timeFormat)} - {endDt.format(timeFormat)}
            </div>
        </>
    );
    let upcomingCardBtn = (
        <Link
            href={{
                pathname: "/centre/[orgName]/[orgID]/select",
                query: {
                    orgName: data.organisation.name,
                    orgID: data.organisation.uid,
                    date: endDt.startOf("d").toISOString(),
                    time: (endDt.hour() % 12) + endDt.minute() / 60,
                    meridiem: endDt.format("A"),
                    duration: 0.5,
                    categoryId: categoryId,
                    extended: true,
                },
            }}
            passHref
        >
            <Button type="link" css={styles.button}>
                {t("extend", {
                    defaultValue: "Extend my booking",
                })}{" "}
                <RightOutlined />
            </Button>
        </Link>
    );
    if (isDailyServiceBooking) {
        upcomingTxt = (
            <>
                {startDt.format(dateFormat)} - {endDt.format(dateFormat)}
            </>
        );
        upcomingCardBtn = (
            <Button type="link" css={styles.button}>
                {t("view", {
                    defaultValue: "View my booking",
                })}{" "}
                <RightOutlined />
            </Button>
        );
    }

    return (
        <Link
            href={{
                pathname: "/user/bookings",
                query: { bookingIds: uid },
            }}
            passHref
        >
            <Card title={upcomingBookingCardTitle} css={styles.card}>
                <div css={styles.bookingTextContainer}>{upcomingTxt}</div>
                {upcomingCardBtn}
            </Card>
        </Link>
    );
};

export default UpcomingBookingCard;
