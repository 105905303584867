import { Image } from "lib/imgproxy";
import { cx } from "class-variance-authority";

export const Avatar = ({
    src,
    index = 0,
    text,
    className,
}: {
    src?: string | null;
    index?: number;
    text?: string | number;
    className?: string;
}): JSX.Element => {
    // outline width is set at 2px because of pixel issue
    const circleCss = "relative rounded-full outline outline-2 outline-white";
    const child = src ? (
        <div className={cx("bg-primary-50 bg-contain", circleCss, className)}>
            <Image
                src={src}
                width="100%"
                height="100%"
                className={"rounded-full"}
                alt="Profile picture"
            />
        </div>
    ) : (
        <div
            className={cx(
                index == 3 ? "bg-primary-50" : "bg-primary-100",
                "h-[24.5px] w-[24.5px] outline-offset-[-0.5px]",
                "flex items-center justify-center text-micro text-primary-500",
                circleCss,
            )}
        >
            {text}
        </div>
    );
    return (
        <div key={index} className={className}>
            {child}
        </div>
    );
};
