import { useState } from "react";
import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import Link from "next/link";

import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "components/Sheet";
import {
    CloseUnfilled,
    OfficeBuildingUnfilled,
    SearchUnfilled,
} from "components/Icons";
import { Input } from "components/Forms";
import { ScrollArea } from "components/ScrollArea";

export const VenueSheet = ({
    venueOptions,
}: {
    venueOptions?: VenueOptionsProps[];
}): JSX.Element => {
    const { t } = useTranslation(["components/HomePage", "common"]);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");

    const filteredOptions: VenueOptionsProps[] | undefined =
        query === ""
            ? venueOptions
            : venueOptions?.filter((o) =>
                  o.text
                      .toLowerCase()
                      .replace(/\s+/g, "")
                      .replace(/[^\w\s]|_/g, "")
                      .includes(
                          query
                              .toLowerCase()
                              .replace(/\s+/g, "")
                              .replace(/[^\w\s]|_/g, ""),
                      ),
              );

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
                <div
                    className={cx(
                        "flex h-[52px] items-center gap-3 rounded-lg border border-solid border-blue-grey-50 bg-white px-3 py-2",
                    )}
                >
                    <SearchUnfilled className="text-blue-grey-600" />
                    <div className="typography-main w-full truncate text-blue-grey-400">
                        {t("searchVenueName", " Search venue name")}
                    </div>
                </div>
            </SheetTrigger>
            <SheetContent className="grid h-full grid-rows-[max-content_1fr]">
                <SheetHeader>
                    <div className="flex justify-between pb-6 pt-7">
                        <SheetTitle className="w-full">
                            {t("searchVenueName", "Search venue name")}
                        </SheetTitle>
                        <button
                            className="border-none bg-transparent outline-none"
                            onClick={() => {
                                setOpen(false);
                                setQuery("");
                            }}
                        >
                            <CloseUnfilled className="text-blue-grey-900" />
                        </button>
                    </div>
                </SheetHeader>
                <div className="flex min-h-0 min-w-0 flex-col px-0.5">
                    <Input
                        placeholder={t("searchVenueName", "Search venue name")}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="my-2"
                    />
                    <ScrollArea className="flex flex-1 pr-2">
                        <ul
                            data-show={
                                (filteredOptions?.length ?? 0 > 0)
                                    ? "true"
                                    : "false"
                            }
                            className="group flex min-w-0 flex-col py-2"
                        >
                            {filteredOptions?.map((o) => (
                                <li
                                    key={o.id}
                                    className="typography-sub relative flex cursor-default select-none gap-2 text-blue-grey-900"
                                >
                                    <span
                                        className={cx(
                                            "flex items-center pl-3 text-blue-grey-300",
                                        )}
                                    >
                                        <OfficeBuildingUnfilled
                                            className="size-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <Link href={o.link}>
                                        <span className="flex-1 truncate py-2 text-blue-grey-900">
                                            {o.text}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                            <div className="px-4 py-2 text-gray-700 group-data-[show=false]:contents group-data-[show=true]:hidden">
                                {t("common:nothingFound", "Nothing found.")}
                            </div>
                        </ul>
                    </ScrollArea>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export type VenueOptionsProps = {
    id: string | number;
    text: string;
    link: string;
};
