import { css } from "@emotion/react";
import Link from "next/link";
import { gql, useQuery } from "@apollo/client";
import { Button, Tag, Skeleton, Badge } from "antd";

import dayjs from "lib/dayjs";
import { cardTypography } from "config/theme";
import { useTranslation } from "react-i18next";

const styles = css({
    ".ant-badge-count,.ant-badge-dot,.ant-badge .ant-scroll-number-custom-component":
        {
            background: "#eb001b",
        },
    width: "100%",
    "& .reattempt-booking-card-root": {
        background: "#ffffff",
        border: "1px solid #e2e2e2",
        boxSizing: "border-box",
        borderRadius: 8,
        padding: 12,
        width: "100%",
        "& > .reattempt-booking-card-label": { marginBottom: 4 },
        "& > .reattempt-booking-card-action": { marginTop: 12 },
        "& > div.reattempt-booking-card-content": {
            paddingLeft: 4,
            "& > div.reattempt-booking-card-tag": {
                marginBottom: 4,
                "& > span": cardTypography.tag,
            },
            "& > div.reattempt-booking-card-title": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginBottom: 8,
                "& > span": cardTypography.title,
            },
            "& > span": cardTypography.content,
        },
    },
});

type ReattemptBookingCardData = { organisation: { uid: string; name: string } };
const reattemptBookingCardQuery = gql`
    query reattemptBookingCard($tenantId: ID!) {
        organisation(uid: $tenantId) {
            uid
            name
        }
    }
`;

type Booking = {
    tenantId: string;
    categoryName?: string;
    start: string;
    end: string;
    cancelled?: string | null;
    confirmed?: string | null;
    courtNames: string;
    isDailyService: boolean;
};
type ReattemptBookingCardProps = {
    booking: Booking;
    className?: string;
    href?: string;
};
const ReattemptBookingCard = ({
    booking,
    className,
    href,
}: ReattemptBookingCardProps): JSX.Element => {
    const { data, loading } = useQuery<ReattemptBookingCardData>(
        reattemptBookingCardQuery,
        { variables: { tenantId: booking.tenantId } },
    );
    const { t, ready } = useTranslation("components/BookingCard");
    const tagContent = {
        PAST: {
            color: "#3DA5D9",
            label: t("tags.past", {
                defaultValue: "PAST BOOKING",
            }),
        },
        ONGOING: {
            color: "#06D6A0",
            label: t("tags.ongoing", {
                defaultValue: "ACTIVE BOOKING",
            }),
        },
        FUTURE: {
            color: "#2364AA",
            label: t("tags.future", {
                defaultValue: "UPCOMING BOOKING",
            }),
        },
        UNCONFIRMED: {
            color: "#EB001B",
            label: t("tags.unconfirmed", {
                defaultValue: "PENDING PAYMENT",
            }),
        },
    };

    if (loading || !data || !ready) {
        return (
            <Badge css={styles}>
                <div
                    className="reattempt-booking-card-root"
                    data-attr="booking-card"
                >
                    <Skeleton active={true} paragraph={{ rows: 2 }} />
                </div>
            </Badge>
        );
    }

    const now = dayjs().unix();
    const start = dayjs(booking.start).tz();
    const end = dayjs(booking.end).tz();

    const bookingStatus = getBookingStatus(booking, end, now, start);

    let startFmt = "DD/M/YY hh:mma";
    let endFmt = "hh:mma";
    if (booking.isDailyService) {
        startFmt = "DD/MM/YY";
        endFmt = "DD/MM/YY";
    }
    const timeTxt = `${start.format(startFmt)} - ${end.format(endFmt)}`;
    const { color, label } = tagContent[bookingStatus];

    return (
        <Badge css={styles} className={className} count={"!"}>
            <div
                className="reattempt-booking-card-root"
                data-attr="booking-card"
            >
                <div className="reattempt-booking-card-label">
                    <Tag color={color}>{label}</Tag>
                </div>
                <div className="reattempt-booking-card-content">
                    <div className="reattempt-booking-card-tag">
                        <span>{booking.categoryName}</span>
                    </div>
                    <div className="reattempt-booking-card-title">
                        <span>{data.organisation.name}</span>
                    </div>
                    <span>
                        {timeTxt}, {booking.courtNames}
                    </span>
                </div>
                <div className="reattempt-booking-card-action">
                    {href && (
                        <Link href={href} passHref>
                            <Button
                                type="primary"
                                block
                                data-attr="continue-checkout-payment"
                            >
                                {t("proceed_payment", {
                                    defaultValue: "Proceed to Payment",
                                })}
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </Badge>
    );
};

export default ReattemptBookingCard;

function getBookingStatus(
    booking: Booking,
    end: dayjs.Dayjs,
    now: number,
    start: dayjs.Dayjs,
): "CANCELLED" | "PAST" | "ONGOING" | "UNCONFIRMED" | "FUTURE" {
    if (booking.cancelled) return "CANCELLED";
    if (!booking.confirmed) return "UNCONFIRMED";
    if (end.unix() < now) return "PAST";
    if (start.unix() < now) return "ONGOING";
    return "FUTURE";
}
